<template>
    <div class="tab-list">
        <div v-for="(item,index) in tabList" 
        :key="index" 
        :class="['tab-item', curIndex == index ? 'active' : '']" 
        @click="to(item.url)">
            <img class="icon" :src="item.icon" />
            <div class="name">{{item.name}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
        	curIndex: {
        		type: Number,
        		default: 0
        	}
        },
        data() {
            return {
                tabList: [
                    {
                        name: this.$t('Assets.FinancialRecords'),
                        icon: require('@/assets/images/pc/assets/tab1.png'),
                        url: '/assets'
                    },
                    {
                        name: this.$t('Assets.Deposit'),
                        icon: require('@/assets/images/pc/assets/tab2.png'),
                        url: '/assets/deposit'
                    },
                    {
                        name: this.$t('Assets.Withdraw'),
                        icon: require('@/assets/images/pc/assets/tab3.png'),
                        url: '/assets/withdraw'
                    },
                    {
                        name: this.$t('Assets.Transfer'),
                        icon: require('@/assets/images/pc/assets/tab4.png'),
                        url: '/assets/transfer'
                    },
                    // {
                    //     name: '电汇',
                    //     icon: require('@/assets/images/pc/assets/tab5.png'),
                    //     url: ''
                    // }
                ]
            }
        },
        created() {
            this.$store.dispatch('getProduct')
        },
        mounted() {
            if (!this.$ws.socket) {
                this.$ws.init()
            }
        },
        activated() {
            if (!this.$ws.socket) {
                this.$ws.init()
            }
        },
        computed: {
            connect() {
                return this.$store.state.connect
            }
        },
        watch: {
            connect(value) {
                let val = JSON.parse(value)
                if(val.status) {
                    this.$ws.sendMsg('{"type":"join","data":"ticker"}')
                }
            }
        },
        methods: {
            to(path) {
                this.$router.push({
                    path
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tab-list {
        min-width: $nav-width;
        max-width: $nav-width;
        margin-right: 25px;
        
        .tab-item {
            display: flex;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
            padding: 20px 24px;
            background-color: #FFFFFF;
            cursor: pointer;
            
            .icon {
                min-width: 24px;
                max-width: 24px;
                height: 24px;
                margin-right: 10px;
            }
            
            .name {
                max-width: 100%;
                font-size: 15px;
                color: #000000;
                word-wrap: break-word;
            }
        }
        
        .active {
            color: #5466EF;
            background-color: #F4F5F9;
        }
    }
</style>